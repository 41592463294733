import { axios } from "@/utils/request";

// 登录
export function login(data) {
  return axios({
    url: '/user/login',
    method: 'post',
    data
  })
}

// 2030101 获取商品分类列表
export function getCategoryList(data) {
  return axios({
    url: '/category/getCategoryList',
    method: 'post',
    data
  })
}

// 2010101 获取Banner列表
export function getBannerVoList(data) {
  return axios({
    url: '/banner/getBannerVoList',
    method: 'post',
    data
  })
}

// 2030201 商品列表分页
export function getGoodsList(data) {
  return axios({
    url: '/storeProduct/getVoListPage',
    method: 'post',
    data
  })
}

// 2030203 获取运费
export function  getShoppingFeeVo(data) {
  return axios({
    url: '/storeProduct/getShoppingFeeVo',
    method: 'post',
    data
  })
}

// 2030202 商品详情
export function getGoods(data) {
  return axios({
    url: '/storeProduct/getVo',
    method: 'post',
    data
  })
}


// 2020203 获取用户地址列表分页
export function getUserAddressVoListByPage(data) {
  return axios({
    url:'/userAddress/getUserAddressVoList',
    method: 'post',
    data
  })
}


// 2020201 增加用户地址
export function addUserAddress(data) {
  return axios({
    url: '/userAddress/addUserAddress',
    method: 'post',
    data
  })
}

// 2020205 删除用户地址
export function deleteUserAddress(data) {
  return axios({
    url: '/userAddress/deleteUserAddress',
    method: 'post',
    data
  })
}

// 2020206 修改默认地址
export function updateUserAddressDefault(data) {
  return axios({
    url: '/userAddress/updateUserAddressDefault',
    method: 'post',
    data
  })
}

// 2020204 获取地址详情
export function getUserAddressVo(data) {
  return axios({
    url: '/userAddress/getUserAddressVo',
    method: 'post',
    data
  })
}

// 2020202 修改用户地址
export function updateUserAddress(data) {
  return axios({
    url: '/userAddress/updateUserAddress',
    method: 'post',
    data
  })
}

// 2020208 获取用户默认地址
export function getUserAddressVoDefault(data) {
  return axios({
    url: '/userAddress/getUserAddressVoDefault',
    method: 'post',
    data
  })
}

// 2060102 下单
export function addOrder(data) {
  return axios({
    url: '/storeOrder/addOrder',
    method: 'post',
    data
  })
}

// 2010101 获取签名
export function getSign(data) {
  return axios({
    url: '/storeOrder/getSign',
    method: 'post',
    data
  })
}

// 2060101 订单列表
export function getOrderList(data) {
  return axios({
    url: '/storeOrder/getList',
    method: 'post',
    data
  })
}

// 2020102 获取用户信息
export function getUserVo(data) {
  return axios({
    url: '/user/getUserVo',
    method: 'post',
    data
  })
}

// 2020401 获取我的银行卡
export function getBank(data) {
  return axios({
    url: '/userBankCard/getVo',
    method: 'post',
    data
  })
}

// 2020402 新增-修改我的银行卡
export function updateBankCard(data) {
  return axios({
    url: '/userBankCard/updateBankCard',
    method: 'post',
    data
  })
}

// 1040102 设备列表
export function getDeviceList(data) {
  return axios({
    url: '/device/getList',
    method: 'post',
    data
  })
}

// 1050101 设备订单列表
export function getDeviceOrder(data){
  return axios({
    url: '/deviceOrder/getList',
    method: 'post',
    data
  })
}

// 1020201 用户账户管理
export function getAccountLogList(data) {
  return axios({
    url: '/userAccount/getAccountLogList',
    method: 'post',
    data
  })
}

// 账户
export function getAccount(data) {
  return axios({
    url: '/userAccount/getAccount',
    method: 'post',
    data
  })
}

// 2020103 修改密码
export function updatePassword(data) {
  return axios({
    url: '/user/updatePassword',
    method: 'post',
    data
  })
}

// 2100105 提现手续费
export function getServiceMoney(data) {
  return axios({
    url: '/draw/getServiceMoney',
    method: 'post',
    data
  })
}

// 2070101 提现
export function cashMoney(data) {
  return axios({
    url: '/draw/add',
    method: 'post',
    data
  })
}

// 2070102 提现列表
export function getCashList(data) {
  return axios({
    url: '/draw/getList',
    method: 'post',
    data
  })
}

// 2020104 获取团队人员
export function getTeam(data) {
  return axios({
    url: '/user/getTeam',
    method: 'post',
    data
  })
}
