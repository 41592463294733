import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    component: () => import('../views/login')
  },
  {
    path: '/address',
    name: 'address',
    meta: { title: '地址管理' },
    component: () => import('../views/address')
  },
  {
    path: '/addAddress',
    name: 'addAddress',
    meta: { title: '新增地址' },
    component: () => import('../views/address/addAddress')
  },
  {
    path: '/bank',
    name: 'bank',
    meta: { title: '银行卡管理' },
    component: () => import('../views/bank')
  },
  {
    path: '/shoppingMall',
    name: 'index',
    meta: { title: '商城' },
    component: () => import('../views/index')
  },
  {
    path: '/goodsDetail/:storeProductId',
    name: 'goodsDetail',
    meta: { title: '商品详情' },
    component: () => import('../views/index/goodsDetail')
  },
  {
    path: '/order',
    name: 'order',
    meta: { title: '商城订单' },
    component: () => import('../views/order')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: { title: '订单详情' },
    component: () => import('../views/order/detail')
  },
  {
    path: '/confirmOrder',
    name: 'confirmOrder',
    meta: { title: '下单' },
    component: () => import('../views/order/confirmOrder')
  },
  {
    path: '/device',
    name: 'device',
    meta: { title: '我的设备' },
    component: () => import('../views/device')
  },
  {
    path: '/',
    name: 'user',
    meta: { title: '我的' },
    component: () => import('../views/user')
  },
  {
    path: '/accountLog',
    name: 'accountLog',
    meta: { title: '账户记录' },
    component: () => import('../views/user/accountLog')
  },
  {
    path: '/addBank',
    name: 'addBank',
    meta: { title: '添加银行卡' },
    component: () => import('../views/bank/addBank')
  },
  {
    path: '/deviceOrder',
    name: 'deviceOrder',
    meta: { title: '设备订单' },
    component: () => import('../views/order/deviceOrder')
  },
  {
    path: '/cashList',
    name: 'cashList',
    meta: { title: '提现记录' },
    component: () => import('../views/user/cashList')
  },
  {
    path: '/team',
    name: 'team',
    meta: { title: '我的团队' },
    component: () => import('../views/user/team')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
