var ua = navigator.userAgent
let isWeChat = ua.toLowerCase().indexOf('micromessenger') !== -1
let token = localStorage.getItem('token')

let code = geturlparam('code')
let appid = 'wx71c59dea6226dde9'

function geturlparam(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var r = window.location.search.substr(1).match(reg)
  if (r !== null) return unescape(r[2])
  return null
}

// 封装授权封装
export function handleAuth() {
  if(isWeChat && token == null) {
    if (code == null || code === '') {
      window.location.href =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        encodeURIComponent(window.location.href) +
        '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
    }
  }
}


