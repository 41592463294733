<template>
  <van-popup v-model="show" position="bottom" @close="handleClose" >
    <div class="content_wrap" >
      <div class="header_wrap" >
        <div>已选商品</div>
        <img src="./img/1.png" class="close_icon" @click="handleClose" />
      </div>

      <div class="goods_list" >
        <div class="goods_item" v-for="(item, index) in cartData" :key="index">
          <img :src="item.image" class="goods_img" />
          <div class="goods_right" >
            <div class="goods_name" >{{ item.storeName }}</div>
            <div class="goods_bottom" >
              <div class="goods_price" >
                <span>￥</span>
                <div>{{ item.price }}</div>
<!--                <span>.99</span>-->
              </div>
              <div class="count_wrap" >
                <img src="./img/2.png" class="count_icon" @click="changeCount(0, index)" />
                <div class="count_text" >{{ item.num }}</div>
                <img src="./img/3.png" class="count_icon" @click="changeCount(1, index)" />
              </div>
            </div>
          </div>
        </div>
        <van-empty description="暂无商品，快去添加吧！" v-if="cartData.length <= 0" />
      </div>
      <div class="freight_money_wrap" >
        运费{{ config.count }}件内<div class="tag_wrap" >￥{{ config.minFee }}</div>，{{ config.count }}件以上加<div class="tag_wrap" >￥{{ config.maxFee - config.minFee }}</div>
      </div>
      <div class="bottom_wrap" >
        <div class="bottom_left" >
          <van-badge :content="count" max="99">
            <img src="~@/views/index/img/3.png" class="cart_img" />
          </van-badge>
          <div class="line" ></div>
          <div class="price_wrap" >
            <div class="total_price" >
              <span>￥</span>
              {{ total }}
            </div>
            <div class="freight_money_text" >
              运费 {{ cartData.length > 0 ? freight : 0 }}元
            </div>
          </div>
        </div>
        <van-button class="buy_button" @click="toConfirm()" >立即结算</van-button>
      </div>
    </div>
  </van-popup>
</template>
<style>
.van-popup {
  background: unset;
}
</style>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {getShoppingFeeVo} from "@/api";
import {
  Toast
} from 'vant'
export default {
  name: 'ShoppingCart',
  data() {
    return {
      show: false,
      config: {
        count: 0,
        minFee: 0,
        maxFee: 0
      },
      total: 0,
      count: 0,
      freight: 0,
      cartData: !sessionStorage.getItem('cartData') ? [] : JSON.parse(sessionStorage.getItem('cartData')),
    }
  },
  methods: {
    toConfirm() {
      if(this.cartData.length <= 0) {
        Toast('请添加下单商品')
        return false
      }
      this.$router.push({
        name: 'confirmOrder'
      })
    },
    handleClose() {
      this.show = false
      this.$emit('close',this.cartData)
    },
    changeCount(type, index) {
      const newData = [...this.cartData]
      let num = newData[index].num
      switch (type) {
        case 0:
          if(num <= 1) {
            newData.splice(index, 1)
          } else {
            newData[index].num = num - 1
          }
          break
        case 1:
          newData[index].num = num + 1
          break
      }
      this.cartData = [...newData]
      sessionStorage.setItem('cartData', JSON.stringify(this.cartData))
      this.getTotal()
    },
    handleShow(data) {
      this.cartData = data
      this.show = true
      this.getTotal()
    },
    getTotal() {
      const { cartData, config } = this
      let total = 0
      let count = 0
      cartData.map(item => {
        total += parseFloat(item.price) * item.num
        count += item.num
      })
      this.count = count
      this.total = total.toFixed(2)
      this.freight = count > config.count ? config.maxFee : config.minFee
    },
    getShoppingFeeVo() {
      getShoppingFeeVo({}).then(res => {
        if(res.success) {
          this.config = res.data
        }
      })
    }
  },
  mounted() {
    this.getShoppingFeeVo()
  }
}
</script>
